<template>
  <fm-modal title="闲置情况" :mask-closable="false" v-model="openModal" @cancel="openModal = false" width="800px">
    <fm-table-new
      :simple-filter="false"
      v-if="openModal"
      :columns="columnList"
      :auto-height="false"
      border="row"
      ref="table"
      :data-list="dataList"
      :stripe="true"
      emptyPlaceholder="-">
    </fm-table-new>
  </fm-modal>
</template>

<script>
import {
  assetsInRequest
} from '../../../../api'

export default {
  data () {
    return {
      dataList: [],
      openModal: false
    }
  },
  computed: {
    columnList: {
      get () {
        let data = [{
          title: '库存记录编号',
          field: 'code',
          width: '130',
          sort: true
        },
        {
          title: '资产编号',
          field: 'assetsCode',
          sort: true
        },
        {
          title: '资产名称',
          field: 'assetsName',
          sort: true
        },
        {
          title: '规格型号',
          field: 'assetsGgxh',
          sort: true
        },
        {
          title: '价值',
          dataType: Number,
          field: 'assetsJz',
          sort: true
        },
        {
          title: '入库机构',
          field: 'inOrgName',
          sort: true
        },
        {
          title: '入库日期',
          field: 'inTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.inTime ? rowData.inTime.slice(0, 10) : '-')
          }
        }]
        return data
      }
    }
  },
  methods: {
    async open (goodsId) {
      this.openModal = true
      this.loadData(goodsId)
    },
    async loadData (goodsId) {
      this.dataList = []
      if (!goodsId) {
        return
      }
      this.dataList = await assetsInRequest.get({
        notOut: 1,
        goodsId: goodsId
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>