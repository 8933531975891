<style lang="less" scoped>
  .budget-block {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    box-sizing: border-box;
    background-color: #FAFAFA;
  }
  .item {
    width: 33.33333333333%;
    padding: 5px;
    box-sizing: border-box;
  }
</style>

<style lang="less" scoped>
  .budget-item {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid #EEE;
    box-sizing: border-box;
    padding: 10px;
    position: relative;
  }
  .status {
    text-align: center;
    border-bottom: 1px solid #EEE;
    padding-bottom: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
  }
  .fmico-error {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .3s;
    &:hover {
      color: red;
    }
  }
  .row {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
</style>

<template>
  <div class="budget-block">
    <div class="item" v-for="item in dataList" :key="item.id">
      <div class="budget-item">
        <div class="status">{{item.statusText}}</div>
        <table style="width: 100%;">
          <tr>
            <td><span>{{item.goodsName || '-'}}</span></td>
            <td colspan="2" style="position: relative;">
              <span :style="{cursor: item.readonly ? 'default' : 'pointer'}" @click="chooseAssets(item)">{{item.goodsAssetsTypeCode && item.goodsAssetsTypeName ? (item.goodsAssetsTypeCode + item.goodsAssetsTypeName) : '关联国有资产编码'}}</span>
              <i class="fmico fmico-error" v-if="item.actions.map(v => v.key).includes('del')"></i>
            </td>
          </tr>
        </table>
        <div class="row" style="justify-content: space-between;">
          <fm-input-new :readonly="item.readonly" v-model="item.price" @change="(v) => rowValueChange('price', v, item)" style="flex: 1;overflow: auto;">
            <div slot="append">元</div>
          </fm-input-new>
          <fm-input-number :readonly="item.readonly" v-model="item.num" @input="(v) => rowValueChange('num', v, item)" :min="1" style="width: 100px;overflow: auto;margin-left: 10px;"></fm-input-number>
          <fm-input-new :readonly="item.readonly" v-model="item.total" @change="(v) => rowValueChange('total', v, item)" style="flex: 1;overflow: auto;margin-left: 10px;">
            <div slot="prepend">共</div>
            <div slot="append">元</div>
          </fm-input-new>
        </div>
        <div class="row" v-if="type !== 'draw'">
          <fm-input-new disabled readonly :value="item.budgetApplyOrgName" style="width: 100%;">
            <div slot="prepend">申请部门</div>
          </fm-input-new>
        </div>
        <div class="row">
          <fm-select :disabled="item.readonly" @change="v => rowValueChange('applyOrgId', v, item)" v-model="item.applyOrgId" style="width: 100%;" filterable placeholder="请选择初审科室">
            <fm-option v-for="org in preOrgList" :key="org.key" :label="org.label" :value="org.key"></fm-option>
          </fm-select>
        </div>
        <div class="row">
          <fm-select :disabled="item.readonly" @change="v => rowValueChange('fgldUserId', v, item)" v-model="item.fgldUserId" style="width: 100%;" filterable placeholder="请选择分管领导">
            <fm-option v-for="User in fgldUserList" :key="User.key" :label="User.label" :value="User.key"></fm-option>
          </fm-select>
        </div>
        <div class="row">
          <fm-input-new :readonly="item.readonly" v-model="item.remark" @change="(v) => rowValueChange('remark', v, item)" style="flex: 1;" placeholder="备注">
            <div slot="prepend">备注</div>
          </fm-input-new>
        </div>
        <div class="row">
          <template v-for="action in item.actions">
            <fm-btn size="small" text :key="action.key" v-if="!['edit', 'del'].includes(action.key)" @click="$emit('action', {action: action, data: item})">{{action.label}}</fm-btn>
          </template>
        </div>
      </div>
    </div>
    <fm-modal v-model="status.goods" width="800px">
      <div v-if="chooseData" style="height: 80vh;position: relative;">
        <assets-form :id="chooseData ? chooseData.goodsId : null" @save="onAssetsSave" @close="status.goods = false" />
      </div>
    </fm-modal>
  </div>
</template>

<script>
import Mixins from './mixins'

export default {
  mixins: [Mixins]
}
</script>
