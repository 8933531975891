<style lang="less" scoped>
  .assets-choose {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .assets-list {
    border-top: 1px solid #EEE;
    margin-top: 10px;
    flex: 1;
    overflow: auto;
    &.empty {
      display: flex; flex-direction: column;align-items: center;justify-content: center;
      .add-notice {
        margin: 0;
        flex-direction: column;
        .btn {
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }
    .add-notice {
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }
  .assets-item {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s;
    &:hover {
      background-color: #F8F8F8;
    }
  }
  .assets-name {
    font-weight: bold;
  }
  .assets-desc {
    color: #888;
    margin-top: 5px;
  }
  .btn {
    background-color: #F8F8F8;
    font-size: 12px;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    padding: 2px 5px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .3s;
    white-space: nowrap;
    &:hover, &:active {
      background-color: #ddd;
    }
    & + .btn {
      margin-left: 5px;
    }
  }
</style>

<template>
  <div class="assets-choose" v-loadingx="loading.search">
    <fm-input-new style="margin: 0 10px;" v-model="search.key" @input="onSearch" placeholder="请输入名称/国有资产编码/国有资产名称" />
    <div class="scrollbar assets-list" :class="{ empty: !dataList.length }">
      <div class="assets-item" v-for="item in dataList" :key="item.id">
        <div>
          <div class="assets-name">{{item.data.name}}</div>
          <div class="assets-desc">
            <span>{{item.data.assetsTypeCode}}</span>
            <span>-</span>
            <span>{{item.data.assetsTypeName}}</span>
          </div>
        </div>
        <div class="btn" @click="$emit('choose', item.data)">添加</div>
      </div>
      <div class="add-notice">
        <div style="font-size: 18px;font-weight:800;">没有找到对应数据？</div>
        <div class="btn" @click="status.form = true">新增</div>
      </div>
    </div>
    <!-- <div class="pager">
      <fm-page :total="total" @change="onPageChange" :current="this.search.page" simple></fm-page>
    </div> -->
    <fm-modal v-model="status.form" width="800px">
      <assets-form @save="onAssetsSave" v-if="status.form" @close="status.form = false" />
    </fm-modal>
  </div>
</template>

<script>
import AssetsForm from './form'

let timer = null

export default {
  components: { AssetsForm },
  data () {
    return {
      dataList: [],
      total: 0,
      loading: {
        search: false
      },
      status: {
        form: false
      },
      search: {
        page: 1,
        size: 10,
        key: null
      }
    }
  },
  computed: {
    goodsList () {
      return this.$store.getters.goodsList
    }
  },
  methods: {
    onAssetsSave (data) {
      this.$emit('choose', data)
      this.status.form = false
      this.loadData()
    },
    onPageChange (page) {
      this.search.page = page
      this.loadData()
    },
    onSearch () {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.search.page = 1
        this.loadData()
      }, 500);
    },
    async loadData () {
      this.loading.search = true
      if (this.search.key) {
        this.dataList = this.goodsList.filter(v => {
          return (v.data.assetsTypeCode || '').indexOf(this.search.key) > -1 || (v.data.name || '').indexOf(this.search.key) > -1 || (v.data.code || '').indexOf(this.search.key) > -1
        })
      } else {
        this.dataList = this.goodsList
      }
      this.loading.search = false
    }
  },
  async mounted () {
    this.loadData()
    this.loading.search = true
    this.dataList = await this.$store.dispatch('loadGoodsList')
    this.loading.search = false
  }
}
</script>
