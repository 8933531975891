/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Fri Apr 01 2022
 */
import AssetsForm from '../assets/form'

export default {
  components: { AssetsForm },
  props: {
    budgetData: { type: Object, default: null },
    dataList: {
      type: Array, default: () => ([])
    },
    statusList: {
      type: Array, default: () => ([])
    },
    type: String
  },
  data () {
    return {
      status: {
        goods: false
      },
      chooseData: null
    }
  },
  computed: {
    columns () {
      let columns = [
        { title: '名称', field: 'goodsName', fixed: 'left', width: 200 },
        { title: '国有资产', field: 'goodsAssetsTypeId', slot: 'assets', filterMethod (parm) {
          let dText = parm.row.goodsAssetsTypeCode + parm.row.goodsAssetsTypeName
          return dText && dText.includes(parm.value)
        }
        },
        { title: '单价', field: 'price', slot: 'price', dataType: Number, filterRange: true },
        { title: '数量', field: 'num', slot: 'num', dataType: Number, filterRange: true },
        { title: '总价', field: 'total', slot: 'total', dataType: Number, filterRange: true},
      ]

      columns = columns.concat([{
        title: '申请部门',
        field: 'budgetApplyOrgName',
        filters: this.applyOrgList.map(v => {
          return {
            label: v.label,
            value: v.label
          }
        })
      },
      { title: '申请人', field: 'userName' }])

      columns = columns.concat(
        { title: '初审科室', field: 'applyOrgName', slot: 'preOrg',
          filters: this.preOrgList.map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        { title: '分管领导', field: 'fgldUserName', slot: 'fgldUser',
          filters: this.fgldUserList.map(v => {
            return {
              label: v.data.name,
              value: v.data.name
            }
          })
        },
        { title: '状态', field: 'statusText',
          width: 100,
          filters: this.statusList.map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        { title: '备注', field: 'remark', slot: 'remark' },
        { title: '操作', field: 'actions', slot: 'actions', fixed: 'right', search: false},
      )
      return columns
    },
    preOrgList () {
      const preOrgIds = this.budgetData ? (this.budgetData.preOrgIds || '').split(',').map(v => Number(v)) : []
      return this.$store.getters.orgList.filter(v => preOrgIds.includes(v.key))
    },
    fgldUserList () {
      const fgldUserIds = this.budgetData ? (this.budgetData.fgldUserIds || '').split(',').map(v => Number(v)) : []
      return this.$store.getters.userList.filter(v => fgldUserIds.includes(v.key))
    },
    applyOrgList () {
      const orgIds = this.budgetData ? (this.budgetData.applyOrgIds || '').split(',').map(v => Number(v)) : []
      return this.$store.getters.orgList.filter(v => orgIds.includes(v.key))
    }
  },
  methods: {
    onSelectChange (data) {
      this.$emit('onSelectChange', data)
    },
    counterChange (data) {
      this.$emit('counterChange', data)
    },
    chooseAssets (data) {
      if (!data.readonly) {
        this.chooseData = data
        this.status.goods = true
      }
    },
    onAssetsSave (goods) {
      this.$set(this.chooseData, 'goodsAssetsTypeCode', goods.assetsTypeCode)
      this.$set(this.chooseData, 'goodsAssetsTypeName', goods.assetsTypeName)
      this.$set(this.chooseData, 'goodsName', goods.name)
      this.status.goods = false
    },
    rowValueChange (field, value, data) {
      // if (field === 'price' && data.maxPrice && data.maxPrice < Number(value)) {
      //   return
      // }
      if (!data.readonly) {
        this.$emit('change', field, value, data)
      }
    }
  }
}
