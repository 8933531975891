<template>
  <fm-modal v-model="modal" :mask-closable="false" width="1000" v-if="modal">
    <div class="p-detial-from">
      <div class="right">
        <fm-title titleText="预算填写"></fm-title>
        <fm-form label-align="left">
          <fm-form-item label="名称">
            <div>{{formData.goodsName}}</div>
          </fm-form-item>
          <fm-form-item label="国有资产">
            <div>{{formData.goodsAssetsTypeName}}</div>
          </fm-form-item>
          <fm-form-item label="数量">
            <fm-input-new @change="onChange('num')" v-model="formData.num" placeholder="数量"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="单价">
            <fm-input-new @change="onChange('price')" v-model="formData.price" placeholder="单价"></fm-input-new>
            <div class="xj" v-if="formData.maxPrice && formData.maxPrice < formData.price">该物品限价{{formData.maxPrice}}元</div>
          </fm-form-item>
          <fm-form-item label="总价">
            <fm-input-new v-model="formData.total" disabled placeholder="总价"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="申请部门">
            <fm-select absolute filterable :clearable="true" v-model="formData.budgetId">
              <fm-option v-for="item in orgBudgetData" :key="item.id" :value="item.id" :label="item.applyOrgName"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="申请人">
            <div>{{formData.id ? formData.userName : $store.getters.userInfo.name}}</div>
          </fm-form-item>
          <fm-form-item label="初审科室">
            <fm-select absolute filterable :clearable="true" v-model="formData.applyOrgId">
              <fm-option v-for="item in preOrgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="分管领导">
            <fm-select absolute filterable :clearable="true" v-model="formData.fgldUserId">
              <fm-option v-for="item in fgldUserList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="备注">
            <fm-input-new type="textarea" v-model="formData.remark" placeholder="备注"></fm-input-new>
          </fm-form-item>
        </fm-form>
        <div class="model-btn">
          <fm-btn style="margin-right: 30px;" @click="formSubmit">添加</fm-btn>
          <fm-btn @click="modal = false">取消</fm-btn>
        </div>
      </div>
      <div class="left">
        <fm-title titleText="历年数据分析"></fm-title>
        <div class="his-data-list" v-if="purchaseCount.length > 0">
          <div class="his-data-item" v-for="(item, index) in purchaseCount" :key="index">
            <div class="year">{{item.year}}年</div>
            <div class="info">采购数量：{{item.sumNum}}</div>
            <div class="info">采购均价：{{item.sumNum && item.sumTotal ? (item.sumTotal / item.sumNum).toFixed(0) + '元' : '无'}}</div>
          </div>
        </div>
        <div v-else class="his-data-list-nodata">
          暂无数据
        </div>
      </div>
    </div>
  </fm-modal>
</template>

<script>
import {
  purchaseRequest
} from '../../../../api'

export default {
  props: {
    budgetData: { type: Object, default: null },
    orgBudgetData: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      formData: {
        goodsId: null,
        applyOrgId: null,
        budgetId: null,
        num: null,
        price: null,
        total: null,
        remark: null,
        fgldUserId: null
      },
      purchaseCount: [],
      modal: false,
      loading: false
    }
  },
  computed: {
    fgldUserList () {
      const fgldUserIds = this.budgetData ? (this.budgetData.fgldUserIds || '').split(',').map(v => Number(v)) : []
      return this.$store.getters.userList.filter(v => fgldUserIds.includes(v.key))
    },
    preOrgList () {
      const preOrgIds = this.budgetData ? (this.budgetData.preOrgIds || '').split(',').map(v => Number(v)) : []
      return this.$store.getters.orgList.filter(v => preOrgIds.includes(v.key))
    }
  },
  methods: {
    async loadPurchaseCount (goodsId) {
      this.purchaseCount = []
      if (!goodsId) {
        return
      }
      this.purchaseCount = await purchaseRequest.getYearCount({goodsId})
    },
    open (data) {
      this.formData = data ? JSON.parse(JSON.stringify(data)) : {
        total: ''
      }
      this.loadPurchaseCount(this.formData.goodsId)
      if (this.orgBudgetData.length === 1) {
        this.formData.budgetId = this.orgBudgetData[0].id
      }
      this.modal = true
    },
    async formSubmit () {
      if (this.formData.maxPrice && this.formData.maxPrice < this.formData.price) {
        this.$notify({
          title: '系统提示',
          message: '该物品限价' + this.formData.maxPrice +'元',
          type: 'warning'
        })
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      await this.$emit('formSubmit', this.formData)
      this.modal = false
      this.loading = false
    },
    onChange (key) {
      if (isNaN(this.formData[key])) {
        this.$notify({
          title: '系统提示',
          message: '请输入数值',
          type: 'warning'
        })
        this.formData[key] = ''
        this.$set(this.formData, 'total', 0)
        return
      }
      if (this.formData.num && this.formData.price && !isNaN(this.formData.num) && !isNaN(this.formData.price)) {
        this.$set(this.formData, 'total', Number((this.formData.num * this.formData.price).toFixed(2)))
      } else {
        this.$set(this.formData, 'total', 0)
      }
    }
  }
}
</script>

<style scoped lang="less">
.p-detial-from {
  display: flex;
  .center {
    max-height: 100%;
    overflow-y: auto;
    width: 380px;
    padding: 0 20px;
  }
  .left {
    flex: 1;
    border-radius: 5px;
    .his-data-list-nodata {
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #aaa;
    }
    .his-data-list {
      max-height: 530px;
      overflow-y: auto;
      width: 100%;
      .his-data-item {
        display: flex;
        background: rgba(0,0,0,0.05);
        border-radius: 5px;
        padding-left: 20px;
        margin-bottom: 20px;
        height: 40px;
        align-items: center;
        .info {
          flex: 1;
        }
        .year {
          font-weight: 500;
          font-size: 18px;
          margin-right: 40px;
        }
      }
    }
  }
  .left-a {
    border: 1px solid #e8eaec;
    border-radius: 5px;
    flex: 1;
    display: flex;
  }
  .right {
    margin-right: 20px;
    width: 500px;
  }
  .budget-detail-list-search {
    margin-left: 10px;
  }
  .budget-detail-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: calc(100% - 80px);
    overflow-y: auto;
  }
  .budget-detail-item {
    background: #e8eaec;
    color: #515a6e;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 5px;
    margin: 10px 0 0 10px;
  }
  .budget-detail-item-a {
    background: #F4628F;
    color: #fff;
  }
}
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.xj {
  position: absolute;
  right: 20px;
  bottom: 0px;
  color: #F4628F;
}
</style>